<template>
  <!-- 选取产品 -->
  <el-drawer :title="$t('addInquiry.SelectProduct')" :visible.sync="dialogFormVisible" :size="700" append-to-body
    :wrapperClosable="false" @close="close">
    <div class="drawer-container">
      <el-form :model="form" :rules="rules" ref="ruleForm" :label-width="lang === 'en' ? '160px' : '80px'">
        <div class="L">
          <div class="dialog-content">
            <!-- 产品名称 -->
            <el-form-item :label="$t('addInquiry.SelectProduct')" prop="codeOrName" :rules="[
                {
                  required: true,
                  message: $t('placeholder.plsInput'),
                  trigger: 'blur',
                },
              ]">
              <el-select v-model.trim="codeOrName" v-loadmore:el-select-dropdown__list="loadMore" filterable remote
                reserve-keyword clearable class="w100" :placeholder="$t('placeholder.plsInput')"
                :remote-method="remoteMethod" :loading="loading" @change="productHandleSelect"
                popper-class="bank-select">
                <el-option v-for="item in productOptions" :key="item.productId" :label="item.value"
                  :value="item.productId"></el-option>
              </el-select>
            </el-form-item>

            <div class="flex product-info">
              <div class="img">
                <img :src="form.productUrl || this.emptyImg" />
                <!-- 产品类型 -->
                <span v-show="form.standardCustomized">
                  {{ productTypeTransl(form.standardCustomized) }}
                </span>
              </div>
              <div class="text">
                <!-- 产品编码 -->
                <p>
                  {{ $t('productCenter.ProductCode') }}: {{ form.productCode }}
                </p>
                <!-- 英文名称 -->
                <p class="ell">
                  <el-tooltip class="item" effect="dark" :content="form.productEn" placement="top-start">
                    <div class="ell">
                      {{ $t('productCenter.EnglishName') }}:
                      {{ form.productEn }}
                    </div>
                  </el-tooltip>
                </p>
                <!-- 产品运营 -->
                <p>
                  {{ $t('productCenter.ProductOperations') }}:
                  {{
                  lang === 'en'
                  ? form.productOperationEn
                  : form.productOperation
                  }}
                </p>
              </div>
            </div>

            <!-- 询盘数量 -->
            <el-form-item v-if="form.printingWay === 'Sample'" :label="$t('inquiryList.InquiryQuantity')"
              prop="selectQuantity">
              <el-input v-model="form.selectQuantity" @blur="calcPrice" maxlength="10" type="number"
                :placeholder="$t('placeholder.plsInput')" clearable></el-input>
            </el-form-item>
            <el-form-item v-else :label="$t('inquiryList.InquiryQuantity')" prop="number">
              <div class="flex">
                <el-input v-model="addNums" maxlength="10" @keyup.enter.native="addNumbers(addNums)" type="number"
                  :min="1" :placeholder="$t('placeholder.plsInput')"></el-input>
                <!-- 添加 -->
                <el-button type="primary" class="ml10" @click="addNumbers(addNums)"
                  :disabled="!addNums || addNums == 0">
                  {{ $t('addInquiry.Add') }}
                </el-button>
                <!-- 默认阶梯 -->
                <el-button class="ml10" @click="defaultLadder" :disabled="!codeOrName ? true : false">
                  {{ $t('addInquiry.DefaultLadder') }}
                </el-button>
              </div>
            </el-form-item>
            <el-form-item class="num-style" v-show="numbers.length">
              <!-- 已选数量： -->
              {{ $t('addInquiry.SelectedNumber') }}
              <el-tag :key="tag" v-for="tag in numbers" closable @close="numbersClose(tag)">
                {{ tag }}
              </el-tag>
            </el-form-item>

            <!-- 基本信息 -->
            <p class="dark">{{ $t('addInquiry.BaseInfo') }}</p>
            <div v-if="info.specsType">
              <div v-for="(item, i) in this.specsValue()" :key="i" class="flex base-info">
                <div class="name" :style="{ width: lang === 'en' ? '160px' : '80px' }">
                  <!-- 规格 -->
                  {{ $t('inquiryDetails.Specification') }}
                </div>
                <div class="res">
                  <el-tag :key="tag" v-for="tag in item.value" :type="
                      tag == (i === 0 ? specsValue1 : specsValue2) ? '' : 'info'
                    " @click="selectSpecsValue(tag, i)">
                    {{ tag }}
                  </el-tag>
                </div>
              </div>
            </div>
            <!-- 颜色 -->
            <el-form-item :label="$t('addInquiry.Color')">
              <el-input v-model="form.color" maxlength="50" :placeholder="$t('placeholder.plsInput')"
                clearable></el-input>
            </el-form-item>

            <!-- 印刷信息 -->
            <p class="dark">{{ $t('addInquiry.PrintingInformation') }}</p>
            <!-- 手动填写 -->
            <el-form-item :label="$t('addInquiry.FillInManually')" v-if="form.standardCustomized === '订制品'">
              <el-switch v-model="form.manualTranslation" @change="manualTranslationChange" :active-value="1"
                :inactive-value="0"></el-switch>
              <span class="ml10">
                {{ $t('inquiryDetails.Tips23') }}
              </span>
            </el-form-item>
            <section v-if="form.manualTranslation === 0">
              <el-row :gutter="0">
                <el-col :span="12">
                  <!-- 印刷方式 -->
                  <el-form-item :label="$t('addInquiry.PrintingMethod')">
                    <el-select v-model="form.printingWayId" :placeholder="$t('placeholder.plsSel')"
                      @change="printingWayChange" clearable>
                      <el-option v-for="item in printingWayOptions" :key="item.printingWayId"
                        :label="lang === 'en' ? item.nameEn : item.nameCn" :value="item.printingWayId"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12" v-show="isPrinting">
                  <!-- 印刷细节 -->
                  <el-form-item :label="$t('addInquiry.PrintingDetails')">
                    <el-select v-model="form.waySelect" :placeholder="$t('placeholder.plsSel')" @change="calcPrice"
                      clearable>
                      <el-option v-for="item in waySelectOptions" :key="item.value" :label="item.label"
                        :value="item.value"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <!-- 印刷位置 -->
              <el-form-item :label="$t('addInquiry.PrintingPosition')" v-show="isPrinting">
                <el-select v-model="form.digitSelect" :placeholder="$t('placeholder.plsSel')" @change="calcPrice"
                  clearable>
                  <el-option v-for="item in digitSelectOptions" :key="item.value" :label="item.label"
                    :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </section>
            <section v-else>
              <el-row>
                <el-col :span="12">
                  <!-- 印刷方式 -->
                  <el-form-item :label="$t('addInquiry.PrintingMethod')">
                    <el-input v-model="form.printingWay" @blur="form.printingWayEn = form.printingWay"
                      :placeholder="$t('placeholder.plsInput')" maxlength="50" clearable></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <!-- 印刷细节 -->
                  <el-form-item :label="$t('addInquiry.PrintingDetails')">
                    <el-input v-model="form.waySelect" :placeholder="$t('placeholder.plsInput')" maxlength="50"
                      clearable></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <!-- 印刷位置 -->
                  <el-form-item :label="$t('addInquiry.PrintingPosition')">
                    <el-input v-model="form.printingPosition" :placeholder="$t('placeholder.plsInput')" maxlength="50"
                      clearable></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </section>

            <!-- 运输信息 -->
            <p class="dark">{{ $t('addInquiry.TransportInformation') }}</p>
            <el-row :gutter="0">
              <el-col :span="12">
                <!-- 收货国家 -->
                <el-form-item :label="$t('addInquiry.ReceivingCountries')" prop="shippingCountry">
                  <el-select v-model="form.shippingCountry" :placeholder="$t('placeholder.plsSel')" clearable
                    @change="calcPrice">
                    <el-option v-for="item in countryOptions" :key="item.code"
                      :label="lang === 'en' ? item.nameEn : item.areaName" :value="item.code"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <!-- 派送到门 -->
                <el-form-item :label="$t('addInquiry.DeliveryDoor')" prop="deliveryToTheDoor">
                  <el-switch v-model="form.deliveryToTheDoor" :active-value="1" :inactive-value="0"></el-switch>
                </el-form-item>
              </el-col>
            </el-row>
            <!-- 邮编 -->
            <el-form-item :label="$t('addInquiry.Postcode')" prop="zipCode" v-show="form.deliveryToTheDoor">
              <el-input v-model="form.zipCode" maxlength="50" :placeholder="$t('placeholder.plsInput')"
                clearable></el-input>
            </el-form-item>
            <!-- 收货地址 -->
            <el-form-item :label="$t('addInquiry.ShippingAddress')" prop="shippingAddress"
              v-show="form.deliveryToTheDoor">
              <el-input type="textarea" :rows="2" maxlength="200" show-word-limit
                :placeholder="$t('placeholder.plsInput')" clearable v-model="form.shippingAddress"></el-input>
            </el-form-item>
            <!-- 需求描述 -->
            <el-form-item :label="$t('addInquiry.RequirementDescription')" prop="demand">
              <el-input type="textarea" :rows="2" maxlength="500" show-word-limit
                :placeholder="$t('placeholder.plsInput')" clearable v-model="form.demand"></el-input>
            </el-form-item>
          </div>
        </div>

        <div class="R" v-show="info.standardCustomized == '标准品'">
          <!-- 价格试算 以下数据为预估值，仅供参考 -->
          <p>
            <span class="dark mr10">{{ $t('addInquiry.PricesForTrial') }}</span>
            {{ $t('addInquiry.TheFollowing') }}
          </p>
          <!-- 运输类型 -->
          <el-form-item :label="$t('addInquiry.ShippingMethod')" :label-width="lang === 'en' ? '135px' : '80px'">
            <!-- 空运 -->
            <el-radio v-model="form.transportType" label="1" @change="calcPrice">
              {{ $t('addInquiry.AirTransportation') }}
            </el-radio>
            <!-- 海运 -->
            <el-radio v-model="form.transportType" label="2" @change="calcPrice">
              {{ $t('addInquiry.SeaTransportation') }}
            </el-radio>
            <!-- 快递 -->
            <el-radio v-model="form.transportType" label="3" @change="calcPrice">
              {{ $t('addInquiry.Expressage') }}
            </el-radio>
          </el-form-item>
          <div class="box">
            <div class="title">
              <ul class="dark">
                <li>Qty</li>
                <li>Net Price</li>
                <li v-show="additionalVisible">Additional Imprint</li>
                <!-- 规格加价 -->
                <section v-if="pricesList.length">
                  <li class="ell" v-for="(item, i) in pricesList[0].addPricePOList"
                    :title="item.specsValue + ' Additional'" :key="i" v-show="item.specsPrice > 0">
                    {{ item.specsValue + ' Additional' }}
                  </li>
                </section>
                <li>Setup Charge</li>
                <li>Total Amount</li>
                <li>Production Time</li>
                <li>Shipping Time</li>
              </ul>
            </div>
            <div class="content" v-if="pricesList.length">
              <div v-for="(item, i) in pricesList" :key="i" :style="{ width: 100 / pricesList.length + '%' }"
                class="price-box">
                <ul>
                  <li class="dark">{{ item.num }}</li>
                  <li>${{ item.netPrice }}</li>
                  <li v-show="additionalVisible">
                    ${{ item.additionalimprint || 0 }}
                  </li>
                  <!-- 规格加价 -->
                  <section v-show="pricesList.length">
                    <li v-for="(_price, n) in pricesList[0].addPricePOList" :key="n" v-show="_price.specsPrice > 0">
                      ${{
                      item.specsPrice
                      ? toFixed3(_price.specsPrice * item.num)
                      : 0
                      }}
                    </li>
                  </section>
                  <li>${{ item.fee || 0 }}</li>
                  <li class="green">${{ calculateTotal(item) }}</li>
                  <li>
                    <span v-if="item.productionTime">
                      {{ item.productionTime
                      }}{{ $t('inquiryDetails.WorkingDay') }}
                    </span>
                    <span v-else>--</span>
                  </li>
                  <li>
                    <span v-if="item.shippingTime">
                      {{ item.shippingTime
                      }}{{ $t('inquiryDetails.WorkingDay') }}
                    </span>
                    <span v-else>--</span>
                  </li>
                </ul>
              </div>
            </div>
            <div v-else class="empty">
              <el-empty :image-size="80"></el-empty>
            </div>
          </div>
        </div>
      </el-form>

      <div class="btns">
        <!-- 确认并继续添加 -->
        <el-button @click="chooseProduct(1)" type="primary" v-show="!isEdit">
          {{ $t('addInquiry.ConfirmAndContinueAdding') }}
        </el-button>
        <!-- 确 认 -->
        <el-button @click="chooseProduct(2)" type="primary">
          {{ $t('addInquiry.Confirm') }}
        </el-button>
        <!-- 取 消 -->
        <el-button @click="close">
          {{ $t('addInquiry.Cancel') }}
        </el-button>
      </div>
    </div>
  </el-drawer>
</template>

<script>
  import { inquiryInteractor } from '@/core/interactors/inquiry/inquiry'
  import { mapGetters } from 'vuex'
  export default {
    props: ['title', 'countryOptions'],
    data() {
      return {
        dialogFormVisible: false,
        codeOrName: '', //根据产品名称、编码进行搜索
        form: {
          sku: '',
          productId: '', // 产品id
          productUrl: '', // 产品图片
          productCode: '', // 产品编码
          productEn: '', // 英文名称
          productCn: '', // 中文名称
          productOperation: '', // 产品运营
          productOperationEn: '', // 产品运营英文
          productOperationId: '', // 产品运营id
          specification: '', // 规格名称
          selectQuantity: '', // 询盘数量
          amount: '',
          color: '', // 颜色
          printingWay: '', // 印刷方式
          printingWayEn: '', // 印刷方式英文
          printingWayId: '', // 印刷方式id
          waySelect: '', // 印刷细节
          digitSelect: '', // 印刷位置
          printingPosition: '', // 印刷位置描述
          shippingCountry: 'US', // 收货国家-默认美国
          zipCode: '', // 邮编
          shippingAddress: '', // 收货地址
          demand: '', // 需求描述
          deliveryToTheDoor: 1, // 派送到门
          transportType: '3', // 运输类型 默认快递
          informationDTOS: [], // 价格阶梯
          manualTranslation: 0, // 手动填写 0:关闭 1:开启
        },
        addNums: '',
        numbers: [],
        specsValue1: '', // 规格1
        specsValue2: '', // 规格2
        printingWayOptions: [], // 印刷方式列表
        waySelectOptions: [], // 印刷细节列表
        digitSelectOptions: [], // 印刷位置列表
        defaultPrint: [
          {
            printingWayId: 'Blank',
            nameCn: 'Blank',
            nameEn: 'Blank',
          },
          {
            printingWayId: 'Sample',
            nameCn: 'Sample',
            nameEn: 'Sample',
          },
        ],
        rules: {},
        info: {},
        specs: [], // 规格列表
        productOptions: [], // 可选产品列表
        pricesList: [], // 价格列表
        additionalVisible: false, // 是否显示价格阶梯additionalimprint
        isPrinting: true, // 是否显示印刷细节和印刷位置
        isEdit: false,
        loading: false,
        emptyImg: require('@/assets/empty_images/picture.png'),
        pageLe: 30,
        pageNo: 1,
        total: 0,
      }
    },
    computed: {
      ...mapGetters({
        lang: 'settings/language',
      }),
      hasMore() {
        return Math.ceil(this.total / this.pageLe) > this.pageNo
      },
    },
    watch: {
      codeOrName(val) {
        if (!val) {
          this.clearData()
        }
      },
      numbers(arr) {
        arr.length ? this.calcPrice() : (this.pricesList = [])
      },
      'form.printingWay'(val) {
        // 手动填写未开启
        if (this.form.manualTranslation === 0) {
          this.form.waySelect = ''
          this.form.digitSelect = ''
          if (val === 'Blank' || !val) {
            this.isPrinting = false
          } else if (val === 'Sample') {
            this.isPrinting = false
            this.addNums = ''
            this.form.selectQuantity = ''
            this.numbers = []
          } else {
            this.getWaySelectOptions(this.info.productPrintingWayVOList)
            this.isPrinting = true
          }
        }
      },
    },
    created() { },
    methods: {
      productTypeTransl(str) {
        if (this.lang === 'en') {
          if (str === '标准品') {
            return 'Standard Product'
          } else if (str === '订制品') {
            return 'Customized Product'
          }
        }
        return str
      },

      //显示弹框
      showAddEdit(str) {
        this.clearData()
        this.dialogFormVisible = true
      },
      loadMore() {
        if (!this.hasMore) return
        this.pageNo++
        this._getOptions(true)
      },
      async remoteMethod(query) {
        this.loading = true
        this.codeOrName = query && query.trim()
        this._getOptions()
      },
      _reset() {
        this.pageNo = 1
        this.total = 0
        this.productOptions = []
      },
      _getOptions(loadMore) {
        if (!loadMore) {
          this._reset()
        }
        inquiryInteractor
          .listEnquiryProductByParam(this.codeOrName, this.pageLe, this.pageNo)
          .then((res) => {
            if (res && res.code === '000000') {
              if (Array.isArray(res?.data.data)) {
                res.data.data = res.data.data.map((item) => {
                  return {
                    ...item,
                    value:
                      item.productCode +
                      ' / ' +
                      (item.nameEn ? item.nameEn : ''),
                  }
                })
              }
              this.loading = false
              this.productOptions = loadMore
                ? this.productOptions.concat(res.data.data)
                : res.data.data
              this.total = res.data.total
            } else {
              this.loading = false
            }
          })
      },

      // 获取编辑产品信息
      getDetail(rows) {
        this.form = JSON.parse(JSON.stringify(rows))
        this.codeOrName = this.form.productCode
        // 处理询盘数量
        if (this.form.printingWay != 'Sample') {
          this.numbers = this.form.selectQuantity
            ? this.form.selectQuantity.split(',')
            : []
        }
        // 处理规格
        if (this.form.specification) {
          this.specsValue1 = this.form.specification.split(',')[0]
          this.specsValue2 = this.form.specification.split(',')[1] || ''
        }
        // 添加延迟事件，防止watch监听事件清空
        setTimeout(() => {
          this.form.waySelect = rows.waySelect // 印刷细节
          this.form.digitSelect = rows.digitSelect // 印刷位置
          this.form.selectQuantity = rows.selectQuantity // 询盘数量
        }, 0)
        this.getProductDetail(this.form.productId)
        this.dialogFormVisible = true
      },

      // 选择产品
      productHandleSelect(item) {
        if (item) {
          this.clearData()
          this.form.productId = item
          this.getProductDetail(item)
        }
      },

      // 规格格式化
      specsValue() {
        let typearr = this.info.specsType.split(',')
        let svalue = this.info.specsValue
        let repalcevalue = svalue.replace(/\'/g, '"')
        let valarr = [[]]
        valarr = JSON.parse(repalcevalue)
        let arr = []
        for (let i = 0; i < typearr.length; i++) {
          let obj = {
            type: typearr[i],
            value: valarr[i],
          }
          arr.push(obj)
        }
        return arr
      },

      //产品详情
      async getProductDetail(productId) {
        let res = await inquiryInteractor.getEnquiryProductDetail(productId)
        if ((res.code = '000000' && res.data)) {
          const data = res.data
          this.info = data
          this.form.productId = data.productId // 产品id
          this.form.productUrl = data.imgMain // 产品图片
          this.form.productCode = data.productCode // 产品编码
          this.form.productCn = data.nameCn // 产品中文名称
          this.form.productEn = data.nameEn // 产品英文名称
          this.form.productOperation = data.belonger // 产品运营
          this.form.productOperationEn = data.belongerEn // 产品运营英文
          this.form.productOperationId = data.belongerId // 产品运营id
          this.form.standardCustomized = data.standardCustomized // 产品类型
          this.specs = data.productSpecsEditVOList || [] // 规格列表
          this.form.sku =
            this.specs?.length === 1 ? this.specs[0].sku : this.form.sku // 单规格默认第一个
          this.getPrintingWay(data.productPrintingWayVOList) // 印刷方式处理
          this.getWaySelectOptions(data.productPrintingWayVOList) // 获取印刷细节印刷位置
        }
      },

      // 印刷方式格式处理
      getPrintingWay(_arr) {
        this.printingWayOptions = []
        if (_arr?.length) {
          _arr.forEach((item) => {
            this.printingWayOptions.push({
              printingWayId: item.printingWaySelectId,
              nameCn: item.printingWaySelect,
              nameEn: item.printingWaySelectEn,
            })
          })
        }
        this.printingWayOptions = this.printingWayOptions.concat(
          this.defaultPrint
        )
      },

      // 印刷细节,印刷位置下拉项数据处理
      getWaySelectOptions(_arr) {
        if (_arr?.length) {
          for (let i = 0; i < _arr.length; i++) {
            if (_arr[i].printingWaySelect === this.form.printingWay) {
              const waySelect = JSON.parse(_arr[i].printingWay)
              const _len = _arr[i].printingPositions.split(',').length
              if (waySelect?.length) {
                // 印刷细节
                this.waySelectOptions = waySelect.map((item) => {
                  return {
                    label: item.way,
                    labelEn: item.pricingValueEn,
                    value: item.way,
                  }
                })
                // 印刷位置
                this.digitSelectOptions = []
                for (let i = 0; i < _len; i++) {
                  this.digitSelectOptions.push({
                    label: i + 1,
                    value: i + 1,
                  })
                }
              }
            }
          }
        }
      },

      // 选择印刷方式
      printingWayChange(val) {
        if (val) {
          let item = this.printingWayOptions.filter(
            (item) => item.printingWayId === val
          )[0]
          this.form.printingWay = item.nameCn
          this.form.printingWayEn = item.nameEn
        } else {
          this.form.printingWay = ''
          this.form.printingWayEn = ''
          this.form.printingWayId = ''
          this.form.waySelect = ''
          this.form.digitSelect = ''
          this.waySelectOptions = []
          this.digitSelectOptions = []
        }
        this.calcPrice()
      },

      // 选择规格
      selectSpecsValue(tag, i) {
        switch (i + 1) {
          case 1:
            this.specsValue1 = tag
            break
          case 2:
            this.specsValue2 = tag
            break
        }
        // 规格数据处理
        if (this.specsValue1 && !this.specsValue2) {
          this.form.specification = this.specsValue1
        } else if (!this.specsValue1 && this.specsValue2) {
          this.form.specification = this.specsValue2
        } else {
          this.form.specification = this.specsValue1 + ',' + this.specsValue2
        }
        this.getSku()
        this.calcPrice()
      },

      // 获取规格SKU
      getSku() {
        if (this.specs[0].specsValue1 && this.specs[0].specsValue2) {
          // 双规格
          for (let i = 0; i < this.specs.length; i++) {
            if (
              this.specsValue1 === this.specs[i].specsValue1 &&
              this.specsValue2 === this.specs[i].specsValue2
            ) {
              this.form.sku = this.specs[i].sku
              break
            }
          }
        } else {
          // 单规格
          for (let i = 0; i < this.specs.length; i++) {
            if (this.specsValue1 === this.specs[i].specsValue1) {
              this.form.sku = this.specs[i].sku
              break
            }
          }
        }
      },

      // 默认阶梯
      defaultLadder() {
        let _arr = [].concat(this.numbers)
        if (this.info?.productPricingStepVOList?.length) {
          this.info.productPricingStepVOList.forEach(
            (item) => item.num > 0 && _arr.push(String(item.num))
          )
          this.numbers = Array.from(new Set(_arr)).splice(0, 5)
        } else {
          this.numbers = []
        }
      },

      // 计算Total Amount
      calculateTotal(item) {
        const _obj = {
          num: item.num,
          netPrice: Number(item.netPrice),
          fee: Number(item.fee) || 0,
          additionalimprint: Number(item.additionalimprint) || 0,
          specsPrice: Number(item.specsPrice) || 0,
        }

        return (
          _obj.num * _obj.netPrice +
          _obj.fee +
          _obj.additionalimprint +
          _obj.specsPrice
        ).toFixed(3)
      },

      // 清空数据
      clearData() {
        this.info = {}
        this.numbers = []
        this.pricesList = []
        this.specsValue1 = ''
        this.specsValue2 = ''
        this.isPrinting = false
        this.form = this.$options.data().form
      },

      //关闭弹框
      close() {
        this.clearData()
        this.codeOrName = ''
        this.dialogFormVisible = false
      },

      // 确认
      chooseProduct(type) {
        if (!this.codeOrName) {
          this.$message.warning(this.$t('addInquiry.PSP'))
          return false
        }

        this.dataFormat()
        if (type === 1) {
          this.$emit('chooseProduct', this.form)
          this.codeOrName = ''
        } else {
          this.$emit('chooseProduct', this.form, this.isEdit)
          this.close()
        }
      },

      // 保存数据格式处理
      dataFormat() {
        // 询盘数量
        this.form.amount = this.numbers[0]
        if (this.form.printingWay != 'Sample') {
          this.form.selectQuantity = this.numbers.join(',')
        }
      },

      // 添加数量
      addNumbers(num) {
        // 询盘数量最多添加5条
        if (this.numbers.length > 4) {
          this.$message.warning(this.$t('inquiryDetails.Tips22'))
          return false
        }
        if (num > 0) {
          if (this.numbers.includes(String(num))) {
            this.addNums = ''
          } else {
            this.numbers.push(String(num))
            this.addNums = ''
          }
        }
      },

      // 删除已选数量
      numbersClose(tag) {
        this.numbers.splice(this.numbers.indexOf(tag), 1)
      },

      // 计算价格阶梯
      async calcPrice() {
        if (!this.isShowPricesList()) return false
        if (this.form.standardCustomized === '订制品') return false

        // 规格处理
        let form = this.form
        let params = {
          specsSelect: form.specification, // 规格类型
          printingWaySelect: form.printingWayId, // 计价方式选择
          waySelect: form.waySelect, // 计价名称选择
          transportType: form.transportType, // 运输类型
          shippingCountry: form.shippingCountry, // 收货国家
          digitSelect: form.digitSelect, // 印刷位选择
          productId: form.productId, // 产品ID
          nums:
            form.printingWay === 'Sample'
              ? form.selectQuantity
              : this.numbers.join(','), // 数量阶梯
        }
        if (params.printingWaySelect && params.transportType && params.nums) {
          let res = await inquiryInteractor.productPricing(params)

          if (res && res.code === '000000') {
            this.pricesList = res.data
            this.isShowAdditional()
          }
        } else {
          this.pricesList = []
        }
      },

      // 判断是否显示价格阶梯
      isShowPricesList() {
        let bool = false
        // 规格不选择，不出现价格阶梯(无规格除外)
        if (this.info.specsType) {
          if (this.info.specsType.indexOf(',') > 0) {
            // 双规格
            this.specsValue1 && this.specsValue2
              ? (bool = true)
              : (bool = false)
          } else {
            // 单规格
            this.specsValue1 ? (bool = true) : (bool = false)
          }
        } else {
          bool = true
        }

        return bool
      },

      // 是否显示additionalimprint
      isShowAdditional() {
        const boo = this.pricesList.every((item) => !item.additionalimprint)
        // 1.印刷方式选择为Blank或Sample 2.计算的Additional Imprint均为0
        if (boo || ['Blank', 'Sample'].includes(this.form.printingWay)) {
          this.additionalVisible = false
        } else {
          this.additionalVisible = true
        }
      },

      // 手动填写切换事件
      manualTranslationChange() {
        this.form.printingWay = ''
        this.form.printingWayEn = ''
        this.form.printingWayId = ''
        this.form.waySelect = ''
        this.form.digitSelect = ''
        this.form.printingPosition = ''
      },
    },
  }
</script>

<style scoped lang="scss">
  .drawer-container {
    width: 100%;
    height: 100%;
    padding-bottom: 70px;
    position: relative;

    .el-form {
      display: flex;
    }

    .L {
      width: 100%;
      height: calc(100vh - 120px);
      padding: 0 0 0 20px;
      border-right: 1px solid #ededed;

      .product-info {
        margin: 20px 0;

        .img {
          width: 72px;
          height: 72px;
          position: relative;

          img {
            width: 72px;
            height: 72px;
          }

          span {
            font-size: 12px;
            position: absolute;
            top: 0;
            right: 0;
            background: #e8f4ff;
            border: 1px solid #d1e9ff;
            color: #409eff;
          }
        }

        .text {
          display: flex;
          flex-flow: column;
          justify-content: space-between;
          width: 88%;
        }

        p {
          margin: 0;
          line-height: 24px;
        }
      }

      .num-style {
        margin-top: -15px;
      }

      p {
        text-indent: 10px;
        margin: 0 0 10px;
      }

      .base-info {
        margin-bottom: 10px;

        .name {
          padding-right: 10px;
          text-align: right;
        }

        .res {
          width: calc(100% - 160px);

          .el-tag {
            cursor: pointer;
            margin-bottom: 5px;
          }
        }
      }
    }

    .R {
      width: 50%;
      padding: 5px 20px 0 10px;
      display: none;

      p {
        margin-bottom: 5px;
        text-indent: 15px;
      }

      .box {
        display: flex;
        padding-left: 15px;

        .title {
          width: 150px;
          background: #f6f8f9;
          margin-top: 1px;
        }

        .content {
          width: calc(100% - 150px);
          overflow-x: auto;
          display: flex;

          .price-box {
            min-width: 100px;
            margin: 1px -1px 0;
          }
        }

        .empty {
          width: calc(100% - 150px);
          margin: 0 auto;
          border: 1px solid #eceff5;
        }

        ul li {
          text-indent: 10px;
          list-style: none;
          line-height: 36px;
          border: 1px solid #eceff5;
          margin-top: -1px;
          margin-right: -1px;
        }
      }
    }

    .btns {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 60px;
      text-align: center;
      padding: 14px 20px;
      border-top: 1px solid #ededed;
    }

    .dialog-content {
      height: 100%;
      overflow: hidden;
      overflow-y: auto;
      padding-right: 20px;
    }
  }

  ::v-deep {
    .el-select-dropdown {
      width: 480px;
    }
  }
</style>

<style>
  .el-form-item__content .bank-select .el-select-dropdown {
    width: 100%;
    overflow: auto;
    overflow-x: hidden;
  }

  .bank-select .el-select-dropdown__list {
    height: 300px !important;

    overflow: auto;
    overflow-x: hidden;
  }

  .bank-select .el-select-dropdown__list li {
    width: 470px !important;
  }
</style>
