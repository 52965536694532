<template>
  <el-dialog :title="$t('companyAddress.Hint')" :visible.sync="dialogVisible" width="200"
    custom-class="customer-companyAddress" :close-on-click-modal="false" :show-close="false">
    <el-row>
      <el-col class="prompt-text">
        <p>{{ $t('companyAddress.HintInfo') }}</p>
        <p>{{ $t('companyAddress.HintInfo1') }}</p>
      </el-col>
      <el-col class="curren-title">
        {{ $t('companyAddress.CurrentCustomerSbusinessAddress') }}：
      </el-col>
      <el-col>
        <el-form ref="form" :key="formKey" :model="form" :rules="rules" label-width="80px" class="demo-form">
          <el-row>
            <el-col :span="8">
              <el-form-item :label="$t('companyAddress.Nation')" prop="companyCountry">
                <el-select v-model="form.companyCountry" :placeholder="$t('placeholder.plsSel')" filterable
                  :filter-method="filterCountry" @change="nationChange" @visible-change="resetCompanyCountryOptions">
                  <el-option v-for="item in companyCountryOptions" :key="item.code" :label="item.name"
                    :value="item.code" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item :label="$t('companyAddress.State')"
                :prop="form.companyCountry === 'US' ? 'companyProvince' : ''">
                <el-select v-model="form.companyProvince" filterable :filter-method="filterSupplier"
                  :placeholder="$t('placeholder.plsSel')" @visible-change="resetCompanySelectProvinceOptions"
                  :allow-create="form.companyCountry !== 'US' && form.companyCountry !=='CA'">
                  <el-option v-for="item in companySelectProvinceOptions" :key="item.code" :label="item.name"
                    :value="item.code" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item :label="$t('companyAddress.City')">
                <el-input v-model="form.companyCity" :placeholder="$t('placeholder.plsInput')" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label-width="lang === 'zh' ? '80px' : '130px'"
                :label="$t('companyAddress.DetailedAddress')">
                <el-input type="textarea" v-model="form.companyAddress" :placeholder="$t('placeholder.plsInput')" />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item :label="$t('cusDetail.ZipCode')" prop="companyPostcode" label-width="80px">
                <el-input v-model="form.companyPostcode" :placeholder="$t('placeholder.plsInput')" />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-col>
    </el-row>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" size="medium " @click="submitFn">
        {{ $t('companyAddress.Submit') }}
      </el-button>
    </span>
  </el-dialog>
</template>
<script>
  import { OrderListInteractor } from '@/core'
  import { mapGetters } from 'vuex'
  import { deepCopy } from 'kits'
  export default {
    name: 'CustomerAddress',

    data() {
      return {
        dialogVisible: false,
        formKey: 1,
        form: {
          companyCountry: '',
          companyProvince: '',
          companyCity: '',
          companyAddress: '',
          companyPostcode: '',
        },
        rules: {
          companyCountry: [
            {
              required: true,
              message: this.$t('placeholder.plsSel'),
              trigger: 'change',
            },
          ],
          companyProvince: [
            {
              required: true,
              message: this.$t('placeholder.plsSel'),
              trigger: 'change',
            },
          ],
          companyCity: [
            {
              required: true,
              message: this.$t('placeholder.plsInput'),
              trigger: 'blur',
            },
          ],
          companyAddress: [
            {
              required: true,
              message: this.$t('placeholder.plsInput'),
              trigger: 'blur',
            },
          ],
        },
        companyProvinceOptions: [], //州下拉框
        companySelectProvinceOptions: [],
        initCompanyProvinceOptions: [],
        companyCountryOptions: [], //国家下拉框
        initCompanyCountryOptions: [], // 国家下拉初始数据
      }
    },
    computed: {
      ...mapGetters({
        lang: 'settings/language',
      }),
    },
    created() {
      // this.getNationFn()
    },
    methods: {
      resetCompanyCountryOptions(val) {
        if (!val) {
          setTimeout(() => {
            this.companyCountryOptions = this.initCompanyCountryOptions
          }, 400)
        }
      },
      resetCompanySelectProvinceOptions(val) {
        if (!val) {
          setTimeout(() => {
            this.companySelectProvinceOptions = this.initCompanyProvinceOptions
          }, 400)
        }
      },
      filterCountry(keyword) {

        let val = keyword.trim().toLowerCase()
        if (val) {
          this.companyCountryOptions =
            this.initCompanyCountryOptions.filter((item, index) => {
              return item.name.toLowerCase().indexOf(val) > -1
            })
        } else {
          this.companyCountryOptions = this.initCompanyCountryOptions
        }
      },
      filterSupplier(keyword) {

        let val = keyword.trim().toLowerCase()
        if (val) {
          this.companySelectProvinceOptions =
            this.initCompanyProvinceOptions.filter((item, index) => {
              return item.name.toLowerCase().indexOf(val) > -1
            })
        } else {
          this.companySelectProvinceOptions = this.initCompanyProvinceOptions
        }
      },
      showCustomerAddress(info, companyCountryOptions) {
        this.companyCountryOptions = companyCountryOptions
        this.initCompanyCountryOptions = deepCopy(companyCountryOptions)
        for (let k in this.form) {
          this.form[k] = info[k] ?? ''
        }
        let countryObj = this.companyCountryOptions?.filter(
          (item) => item.code === this.form.companyCountry
        )?.[0]
        if (countryObj) this.getStateFn(countryObj.id)
        this.dialogVisible = true
        this.$nextTick(() => {
          this.$refs.form.clearValidate()
        })
      },

      // 根据国家获取州
      async getStateFn(val) {
        let data = { parentId: val, level: 1 }
        const res = await OrderListInteractor.listCountryApi(data)
        if (res?.code === '000000') {
          this.initCompanyProvinceOptions = deepCopy(res.data)
          this.companyProvinceOptions = res.data
          this.companySelectProvinceOptions = res.data
        } else {
          this.companyProvinceOptions = []
          this.companySelectProvinceOptions = []
        }
      },
      // 选择国家
      nationChange(val) {
        this.resetCompanyCountryOptions()
        this.formKey++
        this.form.companyProvince = ''

        let idObj = this.companyCountryOptions.filter(
          (item) => item.code === val
        )?.[0]
        // 重置下拉框数据
        this.getStateFn(idObj.id)

      },
      submitFn() {
        this.$refs.form.validate((valid) => {
          if (valid) {
            this.$emit('submitFn', this.form)
            console.log('this.form====', this.form)
            this.dialogVisible = false
          } else {
            console.log('error submit!!')
            return false
          }
        })
      },
    },
  }
</script>
<style scoped lang="scss">
  ::v-deep .customer-companyAddress {
    min-width: 800px !important;

    .el-dialog__header {
      text-align: center;
      font-weight: 700;
      color: #333;
      font-size: 18px;

    }

    .el-dialog__body {

      padding: 20px 40px;

      .prompt-text {
        color: #d9001b;
        line-height: 25px;
        font-size: 13px;
      }

      .curren-title {
        font-size: 14px;
        color: #333;
        padding: 50px 0 20px;
      }

      .required-icon {
        .el-form-item__label {
          &::before {
            content: '*';
            color: #ff4d4f;
            margin-right: 4px;
          }
        }
      }
    }

    .el-dialog__footer {
      text-align: center;
    }
  }
</style>
