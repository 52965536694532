<template>
  <div class="custom-container">
    <el-row>
      <div class="flex justify_start">
        <el-page-header
          :title="$t('other.back')"
          @back="goBack()"
          :content="
            enquiryId && !isClone
              ? $t('addInquiry.EditInquiry')
              : $t('addInquiry.AddInquiry')
          "
        ></el-page-header>
        <!-- 询盘单号 -->
        <div class="ml10 mt3" v-show="isEdit && !isClone">
          {{ $t('addInquiry.InquiryNumber') }}：{{ enquiryCode }}
        </div>
      </div>
    </el-row>

    <div class="inquiry-content" id="pdfDom">
      <el-form :model="form" :rules="rules" ref="ruleForm">
        <!-- 基本信息 -->
        <h4>{{ $t('addInquiry.BaseInfo') }}</h4>
        <el-row :gutter="24" class="mt20">
          <el-col :span="6">
            <!-- 出口抬头 -->
            <el-form-item
              :label="$t('addInquiry.ExportsRise')"
              label-width="100px"
              prop="exportsRiseId"
              :rules="[
                {
                  required: true,
                  trigger: 'change',
                  message: $t('placeholder.plsSel'),
                },
              ]"
            >
              <el-select
                v-model="form.exportsRiseId"
                :placeholder="$t('placeholder.plsSel')"
                clearable
                @change="exportsRiseChange"
              >
                <el-option
                  v-for="item in exportsRiseList"
                  :key="item.amountId"
                  :label="item.amountShort"
                  :value="item.amountId"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!-- 询盘来源 -->
            <el-form-item
              :label="$t('addInquiry.InquirySource')"
              prop="enquirySourceId"
              label-width="120px"
            >
              <el-select
                v-model="form.enquirySourceId"
                :placeholder="$t('placeholder.plsSel')"
                @change="enquirySourceChange"
                clearable
              >
                <el-option
                  v-for="item in enquirySourceNameList"
                  :key="item.amountId"
                  :label="item.amountName"
                  :value="item.amountId"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!-- 客户代表 -->
            <el-form-item
              :label="$t('addInquiry.CustomerRepresentative')"
              prop="businessName"
              label-width="120px"
            >
              <!-- <el-input
                v-if="settings === 'en'"
                @focus="businessChange"
                :placeholder="$t('placeholder.plsSel')"
                v-model="form.businessNameEn"
                clearable
                @change="clearBusinessName"
              ></el-input>
              <el-input
                v-else
                @focus="businessChange"
                :placeholder="$t('placeholder.plsSel')"
                v-model="form.businessName"
                clearable
                @change="clearBusinessName"
              ></el-input> -->
              <UserSelect
                :defaultUser="defaultUser"
                @user-choose="chooseBusiness"
                ref="userselect"
                roleName="业务员"
              ></UserSelect>
            </el-form-item>
          </el-col>
          <el-col :span="6" v-show="isEdit">
            <!-- 订单支持 -->
            <el-form-item
              :label="$t('addInquiry.OrderSupport')"
              prop="fromBusinessName"
              label-width="120px"
            >
              <el-input
                v-if="settings === 'en'"
                @focus="fromBusinessChange"
                v-model="form.fromBusinessNameEn"
                :placeholder="$t('placeholder.plsSel')"
                clearable
                @change="clearFromBusinessName"
              ></el-input>
              <el-input
                v-else
                @focus="fromBusinessChange"
                v-model="form.fromBusinessName"
                :placeholder="$t('placeholder.plsSel')"
                clearable
                @change="clearFromBusinessName"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!-- 客户订单号 -->
            <el-form-item
              :label="$t('addInquiry.CustomerOrderNumber')"
              prop="customerOrderCode"
              label-width="100px"
            >
              <el-input
                v-model="form.customerOrderCode"
                :placeholder="$t('placeholder.plsInput')"
                clearable
                maxlength="50"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- 客户信息 -->
        <h4>{{ $t('addInquiry.CustomerInformation') }}</h4>
        <table class="custom-table no-space">
          <thead>
            <tr>
              <th width="200">
                <span>
                  <i>*</i>
                  <!-- 邮箱 -->
                  {{ $t('addInquiry.Mail') }}
                </span>
              </th>
              <th width="200">
                <span>
                  <i>*</i>
                  <!-- 客户名称/编码 -->
                  {{ $t('addInquiry.CustomerNameCode') }}
                </span>
              </th>
              <th width="300">
                <span>
                  <i>*</i>
                  <!-- 公司名称 -->
                  {{ $t('addInquiry.CompanyName') }}
                </span>
              </th>
              <th width="300">
                <span>
                  <i>*</i>
                  <!-- 公司地址 -->
                  {{ $t('companyAddress.CompanyDetailedAddress') }}
                </span>
              </th>
              <th width="200">
                <!-- 联系电话 -->
                {{ $t('addInquiry.ContactNumber') }}
              </th>
              <th width="300">
                <!-- 客户备注 -->
                {{ $t('addInquiry.CustomerNotes') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <el-form-item
                  prop="customerEmail"
                  :rules="[
                    {
                      required: true,
                      trigger: 'change',
                      message: $t('placeholder.plsInput'),
                    },
                    {
                      message: $t('placeholder.formatError'),
                      pattern: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-autocomplete
                    v-model.trim="form.customerEmail"
                    :fetch-suggestions="querySearchAsyncEmail"
                    :placeholder="$t('placeholder.plsInput')"
                    :disabled="isEdit && !isClone"
                    v-loadmore="loadMore"
                    @select="handleSelect"
                    @blur="customerEmailBlackList(form.customerEmail, 2)"
                    clearable
                  ></el-autocomplete>
                </el-form-item>
              </td>
              <td>
                <el-form-item
                  prop="customerName"
                  :rules="[
                    {
                      required: true,
                      trigger: 'change',
                      message: $t('placeholder.plsSel'),
                    },
                  ]"
                >
                  <el-input
                    v-model="form.customerName"
                    :placeholder="$t('placeholder.plsInput')"
                    :disabled="clientDisabled"
                    clearable
                    maxlength="50"
                    @blur="removeSpace('customerName', form.customerName)"
                  ></el-input>
                </el-form-item>
              </td>
              <td>
                <div class="company-name">
                  <el-form-item
                    prop="companyName"
                    :rules="[
                      {
                        required: true,
                        trigger: 'blur',
                        message: $t('placeholder.plsInput'),
                      },
                    ]"
                  >
                    <el-input
                      v-model.trim="form.companyName"
                      :placeholder="$t('placeholder.plsInput')"
                      :disabled="clientDisabled"
                      @blur="companyNameBlackList(form.companyName)"
                      clearable
                      type="textarea"
                      maxlength="500"
                      show-word-limit
                    ></el-input>
                  </el-form-item>

                  <!-- 公司名称模糊匹配数据 -->
                  <div class="list" v-if="isCompanyNameList">
                    <p class="mb10">
                      系统根据录入的邮箱检索你可能要找的公司
                      <i
                        class="el-icon-close"
                        @click="isCompanyNameList = false"
                      ></i>
                    </p>
                    <ul>
                      <li
                        v-for="(item, i) in companyNameList"
                        :key="i"
                        @click="selectCompanyName(item)"
                        :class="{ red: item.companyStatus === 2 }"
                      >
                        <el-tooltip :content="item.companyName" placement="top">
                          <span class="ell text-left">
                            {{ item.companyName }}
                          </span>
                        </el-tooltip>
                        <span class="ell text-right">
                          {{ item.email }}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </td>
              <td>
                <div class="company-name" v-if="showCompanyDetailedAddress">
                  <span :class="{ 'disable-color': !showEditAddressBol }">
                    {{ companyDetailedAddress }}
                  </span>
                  <i
                    v-if="showEditAddressBol"
                    class="el-icon-edit"
                    style="cursor: pointer"
                    @click="addCompanyAddress"
                  ></i>
                </div>
              </td>
              <td>
                <el-form-item prop="customerPhone">
                  <el-input
                    v-model.trim="form.customerPhone"
                    @blur="removeSpace('customerPhone', form.customerPhone)"
                    :placeholder="$t('placeholder.plsInput')"
                    :disabled="clientDisabled"
                    clearable
                    maxlength="50"
                  ></el-input>
                </el-form-item>
              </td>
              <td>
                <el-form-item prop="customerRemark">
                  <el-input
                    v-model.trim="form.customerRemark"
                    :placeholder="$t('placeholder.plsInput')"
                    clearable
                  ></el-input>
                </el-form-item>
              </td>
            </tr>
          </tbody>
        </table>

        <!-- 产品列表 -->
        <h4>{{ $t('addInquiry.ProductList') }}</h4>
        <div class="pro-list">
          <div class="pro-btn">
            <!-- 选择产品 -->
            <el-button @click="getProduct" type="primary">
              {{ $t('addInquiry.SelectProduct') }}
            </el-button>
            <!-- 新增产品 -->
            <el-button @click="addProduct(null)">
              {{ $t('addInquiry.AddProduct') }}
            </el-button>
          </div>

          <el-table
            v-if="isTable"
            class="dyn-table mt15"
            :data="form.products"
            border
            :header-cell-style="{ textAlign: 'center' }"
            :cell-style="{ textAlign: 'center' }"
          >
            <!-- 序号 -->
            <el-table-column
              type="index"
              :label="$t('addInquiry.SerialNumber')"
              width="55"
            ></el-table-column>
            <!-- 产品图片 -->
            <el-table-column
              prop="productUrl"
              :label="$t('addInquiry.ProductPicture')"
              width="130"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.productUrl" class="product-image">
                  <span>
                    {{ productTypeTransl(scope.row.standardCustomized) }}
                  </span>
                  <img
                    :src="scope.row.productUrl"
                    class="c_pointer"
                    @click="handlePreview(scope.row.productUrl)"
                  />
                </div>
                <div v-else>--</div>
              </template>
            </el-table-column>
            <!-- 产品信息 -->
            <el-table-column
              :label="$t('addInquiry.ProductInfo')"
              min-width="220"
            >
              <template slot-scope="scope">
                <div class="product-info text-left">
                  <!-- 产品编码 英文名称 产品运营 -->
                  <p>
                    {{ $t('productCenter.ProductCode') }}:
                    {{ scope.row.productCode }}
                  </p>
                  <p>
                    {{ $t('productCenter.EnglishName') }}:
                    {{ scope.row.productEn }}
                  </p>
                  <p>
                    {{ $t('orderDetails.ProductOperations') }}:
                    {{
                      lang === 'en'
                        ? scope.row.productOperationEn
                        : scope.row.productOperation
                    }}
                  </p>
                </div>
              </template>
            </el-table-column>
            <!-- 规格名称 -->
            <el-table-column
              prop="specification"
              :label="$t('addInquiry.SpecificationName')"
              width="140"
            ></el-table-column>
            <!-- 询盘数量 -->
            <el-table-column
              prop="selectQuantity"
              :label="$t('addInquiry.Inquiries')"
              width="140"
            ></el-table-column>
            <!-- 印刷方式 -->
            <el-table-column
              prop="printingWay"
              :label="$t('addInquiry.PrintingMethod')"
              width="140"
            >
              <template slot-scope="scope">
                {{
                  lang === 'en'
                    ? scope.row.printingWayEn
                    : scope.row.printingWay
                }}
              </template>
            </el-table-column>
            <!-- 印刷细节 -->
            <el-table-column
              prop="waySelect"
              :label="$t('addInquiry.PrintingDetails')"
              width="140"
            ></el-table-column>
            <!-- 印刷位置 -->
            <el-table-column
              prop="digitSelect"
              :label="$t('addInquiry.PrintingPosition')"
              width="140"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.digitSelect">
                  {{ scope.row.digitSelect }}
                  {{ $t('addInquiry.Position') }}
                </span>
                <span v-else>{{ scope.row.printingPosition || '--' }}</span>
              </template>
            </el-table-column>
            <!-- 需求描述 -->
            <el-table-column
              prop="demand"
              :label="$t('addInquiry.RequirementDescription')"
              min-width="180"
            >
              <template slot-scope="scope">
                <div class="limit-text" :title="scope.row.demand">
                  {{ scope.row.demand }}
                </div>
              </template>
            </el-table-column>
            <!-- 收货国家 -->
            <el-table-column
              prop="shippingCountry"
              :label="$t('addInquiry.ReceivingCountries')"
              width="120"
            >
              <template slot-scope="scope">
                {{ getCountry(scope.row.shippingCountry) }}
              </template>
            </el-table-column>
            <!-- 派送到门 -->
            <el-table-column
              prop="deliveryToTheDoor"
              :label="$t('addInquiry.DeliveryDoor')"
              width="120"
            >
              <template slot-scope="scope">
                <span v-if="lang === 'en'">
                  {{ scope.row.deliveryToTheDoor === 1 ? 'Yes' : 'No' }}
                </span>
                <span v-else>
                  {{ scope.row.deliveryToTheDoor === 1 ? '是' : '否' }}
                </span>
              </template>
            </el-table-column>
            <!-- 邮编 -->
            <el-table-column
              prop="zipCode"
              :label="$t('addInquiry.Postcode')"
              width="120"
            ></el-table-column>
            <!-- 收货地址 -->
            <el-table-column
              prop="shippingAddress"
              :label="$t('addInquiry.ShippingAddress')"
              min-width="180"
            >
              <template slot-scope="scope">
                <div class="limit-text" :title="scope.row.shippingAddress">
                  {{ scope.row.shippingAddress }}
                </div>
              </template>
            </el-table-column>
            <!-- 操作 -->
            <el-table-column
              :label="$t('addInquiry.Operate')"
              width="120"
              fixed="right"
            >
              <template slot-scope="scope">
                <el-button
                  class="icon-b"
                  icon="el-icon-edit"
                  circle
                  @click="edit(scope.$index)"
                ></el-button>
                <el-button
                  class="icon-r"
                  icon="el-icon-delete"
                  circle
                  @click="del(scope.$index)"
                ></el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 备注 -->
        <p class="mt10 mb10">
          {{ $t('addInquiry.Remark') }}
        </p>
        <el-row>
          <el-col :span="12">
            <el-dropdown
              class="quick-remake"
              placement="top-start"
              @command="chooseRemake"
            >
              <span class="el-dropdown-link">
                <el-input
                  :disabled="form.orderSource > 0 && form.orderSource != 99"
                  type="textarea"
                  v-model="form.demand"
                  maxlength="500"
                  show-word-limit
                  :rows="3"
                ></el-input>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="(item, i) in quickRemaks"
                  :command="item.code"
                  :key="i"
                >
                  {{ lang === 'en' ? item.messageEn : item.messageCn }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-col>
        </el-row>
        <!-- 附件 -->
        <p class="mt20">
          {{ $t('addInquiry.Appendix') }}
        </p>
        <ErpUpload
          ref="ErpUpload"
          :uploadParams="uploadParams"
          @uploadList="uploadList"
          @initUpload="initUpload"
        ></ErpUpload>
        <div class="wrapper-mail mt10">
          <!-- 发送邮件给客户 -->
          <el-checkbox v-model="sendMailToCustomer">
            {{ $t('addInquiry.SendMailToCustomers') }}
          </el-checkbox>
        </div>
      </el-form>
    </div>

    <div class="oper-btn">
      <!-- 取消 -->
      <el-button @click="goBack()">
        {{ $t('addInquiry.Cancel') }}
      </el-button>
      <!-- 保存 -->
      <el-button @click="save" type="primary" :loading="saveLoading">
        {{ $t('addInquiry.Save') }}
      </el-button>
      <!-- 确定并继续新增 -->
      <el-button
        @click="createInquiry(1)"
        type="primary"
        v-show="!enquiryId"
        :loading="addLoading"
      >
        {{ $t('addInquiry.ConfirmAndContinueAdding') }}
      </el-button>
      <!-- 确认 -->
      <el-button
        @click="createInquiry(2)"
        type="primary"
        :loading="submitLoading"
      >
        {{ $t('addInquiry.Confirm') }}
      </el-button>
    </div>

    <!-- 图片预览 -->
    <el-image-viewer
      v-if="showViewer"
      :on-close="closeViewer"
      :url-list="imgUrl"
    />

    <!-- 添加成员 -->
    <UserChoose ref="UserChoose" @choose-user="chooseUser" />
    <!-- 新增产品 -->
    <add-product
      ref="AddProduct"
      :countryOptions="countryOptions"
      @chooseProduct="chooseProduct"
    ></add-product>
    <!-- 选取产品 -->
    <chooseProduct
      ref="chooseProduct"
      title="选取产品"
      :countryOptions="countryOptions"
      @chooseProduct="chooseProduct"
    />
    <!-- 新增客户地址 -->
    <CompanyAddress ref="CompanyAddress" @submitFn="submitFn" />
  </div>
</template>

<script>
  let emailList = []
  import {
    orderGetDict,
    orderEnquiryAdd,
    orderEnquiryUpdate,
    orderEnquiryDetail,
    enquiryClone,
    saveUpdatePO,
    listOrderSource,
  } from '@/api/order'
  import { clientOrderInstance } from '@/core/interactors/client-supplier/client-order'
  import { FreightSettingInteractor } from '@/core/interactors/product/freightSetting'
  import { getDetailForEmail } from '@/api/mail/contacts.js'
  import { transpotwayList } from '@/api/shipment/shipment-plan'
  import UserChoose from '@/components/userChoose'
  import chooseProduct from './components/chooseProduct'
  import addProduct from './components/addProduct'
  import { formatDataToTreeChecked } from 'kits'
  import { deepCopy } from 'kits'
  import { mapGetters } from 'vuex'
  import { mapState } from 'vuex'
  import UserSelect from '@/components/user-select'
  import userSelectMixin from '@/components/user-select/mixin'
  import CompanyAddress from '@/components/CustomerAddress'
  import { OrderListInteractor } from '@/core'
  export default {
    name: 'inquiry-add',
    mixins: [userSelectMixin],
    components: {
      UserChoose,
      chooseProduct,
      addProduct,
      UserSelect,
      CompanyAddress,
    },
    data() {
      return {
        form: {
          /**
           * 基本信息
           */
          exportsRise: '', //出口抬头
          exportsRiseId: '', //出口抬头id
          enquirySourceName: '', //询盘来源
          enquirySourceId: '', //询盘来源id
          businessId: '', //客户代表id
          businessName: '', //客户代表名称
          businessNameEn: '', //客户代表英文名称
          fromBusinessId: '', //订单支持ids
          fromBusinessName: '', //订单支持名称
          fromBusinessNameEn: '', //订单支持名称英文
          customerOrderCode: '', //客户订单号
          /**
           * 客户信息
           */
          customerName: '', //客户名称
          customerCode: '', //客户编码
          customerEmail: '', //邮箱
          companyName: '', //公司名称
          customerPhone: '', //联系电话
          customerRemark: '', //客户备注
          demand: '', //需求描述
          companyCountry: '', //国家
          companyProvince: '', //州
          companyCity: '', //市
          companyPostcode: '', //邮编

          companyAddress: '', //公司地址
          orderSource: '', // 订单来源，选择快捷备注传入
          /**
           * 产品列表
           */
          products: [],
          /**
           * 附件
           */
          accessoryUrl: '',
        },
        tId: '',
        enquiryId: '',
        enquiryCode: '',
        businessType: '',
        businessArray: [],
        formBusinessArray: [],
        exportsRiseList: [],
        enquirySourceNameList: [],
        transpWayList: [],
        countryOptions: [],
        companyNameList: [],
        quickRemaks: [],
        rules: {},
        isEdit: false,
        isTable: true,
        uploadParams: {
          size: 1024 * 1024 * 20, //单个文件上传大小
        },
        customResult: [], // 客户信息集合
        chooseIndex: 0, // 编辑产品索引
        sendMailToCustomer: false,
        showViewer: false, //图片预览参数
        imgUrl: '', //图片预览url
        isClone: false, // 克隆
        clientDisabled: false,
        isCompanyNameList: false,
        addLoading: false,
        saveLoading: false,
        submitLoading: false,
        pageLe: 30,
        pageNo: 1,
        total: 0,
        cb: null,
        clearEmail: false,
        showEditAddressBol: true,
        computedCompanyDetailedAddress: '',
        showCompanyDetailedAddress: true,
        productsCopy: [], // 【编辑页】初始化时保留一份产品列表，用于保存和提交时比对数据是否发生变化
      }
    },
    computed: {
      ...mapState({
        accountList: (state) => state.mail.accountList,
      }),
      ...mapGetters({
        userInfo: 'user/userInfo',
        settings: 'settings/language',
        lang: 'settings/language',
      }),
      hasMore() {
        return Math.ceil(this.total / this.pageLe) > this.pageNo
      },
      companyDetailedAddress() {
        return this.computedCompanyDetailedAddress
      },
    },
    watch: {
      settings() {
        this.isTable = false
        this.isTable = true
      },
      lang() {
        if (this.form.orderSource > 0 && this.form.orderSource != 99) {
          const item = this.quickRemaks.find(
            (item) => item.code === this.form.orderSource
          )
          this.form.demand =
            this.lang === 'en' ? item.messageEn : item.messageCn
        }
      },
      'form.customerEmail': function (val) {
        if (!val) {
          this.clientDisabled = false
          this.isCompanyNameList = false
          this.showEditAddressBol = true
        }
      },
      'form.companyName': function () {
        this._resetPageParams()
      },
    },
    created() {
      this.getDict()
      this.getCustomResult()
      if (!this.$route.query?.clone && !this.$route.query?.clone) {
        this.countryList()
      }
      this.tId = this.userInfo.tenantId
      if (this.$route.query?.clone) {
        this.isClone = true
        this.clientDisabled = true
        this.showCompanyDetailedAddress = false
      }
      if (this.$route.query.enquiryId) {
        this.isEdit = true
        this.clientDisabled = true
        this.enquiryId = this.$route.query.enquiryId
        this.enquiryCode = this.$route.query.enquiryCode
        this.getEdit(this.enquiryId, this.isClone)
        this.showCompanyDetailedAddress = false
      }
    },
    methods: {
      // 获取国家列表
      async countryList() {
        let data = { level: 0 }
        const res = await OrderListInteractor.listCountryApi(data)
        if (res?.code === '000000') {
          this.countryOptions = res.data
        } else {
          this.countryOptions = []
        }
      },
      // 根据国家获取州
      async getStateFn(val) {
        let data = { parentId: val, level: 1 }
        const res = await OrderListInteractor.listCountryApi(data)
        if (res?.code === '000000') {
          return res.data
        } else {
          return []
        }
      },
      // 获取名称
      getDetailedAddress() {
        let countryObj = this.countryOptions?.filter(
          (item) => item.code === this.form.companyCountry
        )?.[0]
        if (!countryObj) {
          this.computedCompanyDetailedAddress = this.form.companyAddress
          this.showCompanyDetailedAddress = true
          return
        }
        this.getStateFn(countryObj.id)
          .then((data) => {
            let ProvinceObj = data.filter(
              (item) => item.code === this.form.companyProvince
            )?.[0]
            let companyCountry = countryObj.name
            let companyProvince = ProvinceObj?.name
            let addressArr = [
              companyCountry,
              companyProvince || this.form.companyProvince,
              this.form.companyCity,
              this.form.companyAddress,
            ]
            this.computedCompanyDetailedAddress = addressArr
              .filter((item) => item)
              .join('/')
            this.showCompanyDetailedAddress = true
          })
          .catch(() => {
            this.showCompanyDetailedAddress = true
          })
      },
      // 返回收货国家
      getCountry(code) {
        const item = this.countryOptions.filter((item) => item.code === code)[0]
        if (item) {
          return item.name
        }
      },

      // 产品类型转化
      productTypeTransl(str) {
        if (this.lang === 'en') {
          if (str === '标准品') {
            return 'Standard Product'
          } else if (str === '订制品') {
            return 'Customized Product'
          }
        }
        return str
      },

      // 清空校验信息
      clearValidate() {
        this.$refs.ruleForm.clearValidate()
      },

      /**
       * 复制文本清除空格
       * @param {field} 表单字符
       * @param {val} 值
       */
      removeSpace(field, val) {
        this.form[field] = val.replace(/^\s+|\s+$/g, '')
      },

      // 邮箱新增询盘单
      async fromMail() {
        if (this.$route.query.sendEmail) {
          // 跟进邮箱获取客户信息
          this.form.enquirySourceName = 'Email' //询盘来源 Email
          this.form.enquirySourceId = '281143558366171136' //询盘来源 Email

          let data = null
          if (this.accountList.length) {
            data = this.accountList.find((item) => {
              return item.emailAccount == this.$route.query.sendEmail
            })
          } else {
            let res = await this.$store.dispatch('mail/getMailAccountList')
            data = res.find((item) => {
              return item.emailAccount == this.$route.query.sendEmail
            })
          }
          if (data) {
            // 赋值出口抬头
            let head = this.exportsRiseList.find((item) => {
              return item.amountShort == data.exportHead
            })
            this.form.exportsRiseId = head ? head.amountId : ''
          }
          getDetailForEmail({ email: this.$route.query.sendEmail }).then(
            (res) => {
              if (res.code === '000000') {
                if (res.data && res.data.infoId) {
                  let {
                    customerName,
                    customerCode,
                    email,
                    companyName,
                    phone,
                    remark,
                    infoId,
                  } = res.data
                  this.form.customerName = customerName //客户名称
                  this.form.customerCode = customerCode //客户编码
                  this.form.customerEmail = email //邮箱
                  this.form.companyName = companyName //公司名称
                  this.form.customerPhone = phone //联系电话
                  this.form.customerRemark = remark //客户备注
                  this.form['infoId'] = infoId //客户备注
                }
              }
            }
          )
        }
      },

      getDict() {
        // 获取出口抬头
        orderGetDict({ amountType: 1 }).then((res) => {
          let arr = res?.data || []

          if (arr.length) {
            arr = arr.filter((item) => item.amountStatus == 1)
            // 出口抬头呈现顺序RP,RG,SW
            arr.map((item, i) => {
              if (item.amountShort === 'SW') {
                arr.unshift(arr.splice(i, 1)[0])
              }
            })
            arr.map((item, i) => {
              if (item.amountShort === 'RG') {
                arr.unshift(arr.splice(i, 1)[0])
              }
            })
            arr.map((item, i) => {
              if (item.amountShort === 'RP') {
                arr.unshift(arr.splice(i, 1)[0])
              }
            })
            this.exportsRiseList = arr

            this.fromMail()
          }
        })
        // 获取询盘来源
        orderGetDict({ amountType: 2 }).then((res) => {
          this.enquirySourceNameList = res.data
        })
        // 获取运输方式
        transpotwayList().then((res) => {
          if(res?.code === '000000') {
            this.transpWayList = res.data.map((item) => {
              return {
                label: item.transportWayCn,
                labelEn: item.transportWayEn,
                value: item.freightSettingId,
                type: item.transportType,
              }
            })
          }
        })
        // 获取订单来源列表
        listOrderSource().then((res) => {
          this.quickRemaks = res?.data || []
        })
      },

      // 选择出口抬头
      exportsRiseChange(val) {
        this.exportsRiseList.find((item) => {
          if (item.amountId == val) {
            this.form.exportsRise = item.amountShort
          }
        })
      },

      // 选择询盘来源
      enquirySourceChange(val) {
        this.enquirySourceNameList.find((item) => {
          if (item.amountId == val) {
            this.form.enquirySourceName = item.amountName
          }
        })
      },

      // 编辑初始化数据
      async getEdit(id, clone) {
        await this.countryList()
        let response = ''
        if (!clone) {
          //编辑
          response = await orderEnquiryDetail({ businessId: id })
          if (response.code === '000000' && response?.data?.products?.length) {
            this.productsCopy = deepCopy(response.data.products)
          }
        } else {
          //克隆编辑
          response = await enquiryClone({ enquiryId: id })
        }
        if (response.code === '000000') {
          const data = response.data
          const form = this.form

          form.customerCode = data.customerCode
          form.exportsRise = data.exportsRise
          form.exportsRiseId = data.exportsRiseId
          form.enquirySourceName = data.enquirySourceName
          form.enquirySourceId = data.enquirySourceId
          form.businessId = data.businessId
          form.businessName = data.businessName
          form.businessNameEn = data.businessNameEn
          form.fromBusinessId = data.fromBusinessId
          form.fromBusinessName = data.fromBusinessName
          form.fromBusinessNameEn = data.fromBusinessNameEn
          form.customerOrderCode = data.customerOrderCode
          form.customerName = data.customerName
          form.customerEmail = data.customerEmail
          form.companyName = data.companyName
          form.customerPhone = data.customerPhone
          form.customerRemark = data.customerRemark
          form.demand = data.demand
          form.orderSource = data.orderSource
          form.products = data?.products || []
          form.infoId = data.infoId

          form.companyCountry = data.companyCountry ?? '' //国家
          form.companyProvince = data.companyProvince ?? '' //州
          form.companyCity = data.companyCity ?? '' //市
          form.companyPostcode = data.companyPostcode ?? '' //邮编
          form.companyAddress = data.companyAddress

          this.getDetailedAddress()
          if (form.companyCountry) {
            this.showEditAddressBol = false
          } else {
            this.showEditAddressBol = true
          }
          // 用于 客户代表 tree 回显
          this.businessArray = formatDataToTreeChecked(
            data.businessId,
            data.businessName,
            data.businessNameEn
          )
          this.defaultUser = {
            userId: form.businessId,
            userName: form.businessName,
            englishName: form.businessNameEn,
          }

          // 用于 订单支持 tree 回显
          this.formBusinessArray = formatDataToTreeChecked(
            data.fromBusinessId,
            data.fromBusinessName,
            data.fromBusinessNameEn
          )
          form.accessoryUrl = data.accessoryUrl
          if (data.accessoryUrl) this.initUpload(data.accessoryUrl)
        }
      },

      initUpload(data) {
        this.$refs.ErpUpload.initUpload(data)
      },

      // 默认获取所有的客户信息
      async getCustomResult() {
        const res = await clientOrderInstance.getAllCustomer({
          email: '',
          pageNo: this.pageNo,
          pageLe: this.pageLe,
        })

        if (res.code === '000000') {
          this.customResult =
            res?.data?.data.map((item) => {
              return {
                ...item,
                value: item.email,
              }
            }) || []
        }
      },
      _resetPageParams() {
        this.pageNo = 1
        this.total = 0
        emailList = []
      },

      loadMore() {
        if (!this.hasMore) return
        this.pageNo++
        this.querySearchAsyncEmail('', this.cb)
      },

      // 邮箱模糊查询
      async querySearchAsyncEmail(queryString, cb) {
        if (queryString) {
          this.pageNo = 1
        }
        this.cb = cb
        const _res = await clientOrderInstance.getAllCustomer({
          email: queryString.trim(),
          pageNo: this.pageNo,
          pageLe: this.pageLe,
        })
        if (_res.code === '000000') {
          if (_res?.data?.data.length) {
            let results = _res.data.data.map((item) => {
              return {
                ...item,
                value: item.email,
              }
            })
            if (!queryString) {
              emailList = emailList.concat(results || [])
            } else {
              emailList = results
            }
            this.total = _res.data.total
            cb(emailList)
          } else {
            cb([])
            // 精确查询不到结果，走模糊查询
            const _mail = this.form.customerEmail
            const _reg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/

            if (_reg.test(_mail)) {
              const _res = await clientOrderInstance.getAllCustomerByEmail(
                _mail
              )

              this.companyNameList = _res.data?.map((item) => {
                return {
                  companyName: item.companyName,
                  email: item.email,
                  companyStatus: item.companyStatus,
                }
              }) || []
              this.companyNameList.length
                ? (this.isCompanyNameList = true)
                : (this.isCompanyNameList = false)
            }
          }
        }
      },

      /**
       * @description 校验邮箱黑名单
       * @param {type} 1:选择邮箱 2：输入邮箱
       */
      async customerEmailBlackList(data, type) {
        this._resetPageParams()
        const email = type === 1 ? data.email : data
        const _reg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/

        // 输入邮箱清空客户Id
        if (type === 2) this.form.infoId = ''
        if (!email) {
          this.companyNameList = []
          this.isCompanyNameList = false
          return false
        }
        if (_reg.test(email)) {
          const _res = await clientOrderInstance.getInfoTypeByEmail(email)
          // 黑名单用户
          if (_res?.data === 2) {
            this.$message.warning(this.$t('reqmsg.M2069'))
            this.isCompanyNameList = false
          } else {
            if (_res?.data === null)
              this.$message.warning(this.$t('reqmsg.M2070'))
          }
          type === 1 ? this.handleSelect(data, true) : ''
        } else {
          emailList = []
        }
      },
      // 根据公司名称查询公司地址
      async findByNameApiFn(companyName) {
        const res = await clientOrderInstance.findByNameApi(companyName)
        if (res.code === '000000') {
          if (res.data) {
            const { country, province, city } = res.data
            const address = res.data?.companyAddress
            if (!country && !province && !city && !address) return
            const {
              companyCountry,
              companyProvince,
              companyCity,
              companyAddress,
            } = this.form
            if (
              !companyCountry &&
              !companyProvince &&
              !companyCity &&
              !companyAddress
            ) {
              this.form.companyCountry = country
              this.form.companyProvince = province
              this.form.companyCity = city
              this.form.companyAddress = address
              this.getDetailedAddress()
              return
            }
            if (
              country != companyCountry ||
              province != companyProvince ||
              city != companyCity ||
              address != companyAddress
            ) {
              this.$confirm(
                this.$t('placeholder.addressAlert'),
                this.$t('other.info'),
                {
                  type: 'warning',
                }
              )
                .then(() => {
                  this.form.companyCountry = country
                  this.form.companyProvince = province
                  this.form.companyCity = city
                  this.form.companyAddress = address
                  this.getDetailedAddress()
                })
                .catch(() => {})
            }
          }
        }
      },
      /**
       * @description 校验公司名称黑名单
       * @param {companyName} 公司名称
       */
      async companyNameBlackList(companyName) {
        if (companyName) {
          this.findByNameApiFn(companyName)
          const _res = await clientOrderInstance.getCompanyTypeByName(
            companyName
          )

          // 黑名单用户
          if (_res.data === 2) {
            this.$message.warning(this.$t('reqmsg.M2068'))
          }
          this.isCompanyNameList = false
        } else {
          if (this.companyNameList.length) this.isCompanyNameList = true
        }

        this.form.receivingAddressDTOS = [{}]
        this.removeSpace('companyName', this.form.companyName)
      },
      // 点击新增地址
      submitFn(obj) {
        let {
          companyCountry,
          companyProvince,
          companyCity,
          companyAddress,
          companyPostcode,
        } = obj
        this.form.companyAddress = companyAddress ?? ''
        this.form.companyCountry = companyCountry ?? '' //国家
        this.form.companyProvince = companyProvince ?? '' //州
        this.form.companyCity = companyCity ?? '' //市
        this.form.companyPostcode = companyPostcode ?? '' //邮编
        this.getDetailedAddress()
        // this.showEditAddressBol = false
      },
      // 选择邮箱
      handleSelect(item, bool) {
        if (bool) {
          this.form.infoId = item.infoId
          this.form.customerName = item.linkman
          this.form.customerCode = item.customerCode
          this.form.customerEmail = item.email
          this.form.companyName = item.companyName
          this.form.customerPhone = item.phone
          this.form.customerRemark = item.remark
          this.form.companyAddress = item.companyAddress
          this.form.companyCountry = item.companyCountry ?? '' //国家
          this.form.companyProvince = item.companyProvince ?? '' //州
          this.form.companyCity = item.companyCity ?? '' //市
          this.form.companyPostcode = item.companyPostcode ?? '' //邮编
          if (!!this.form.companyCountry) {
            this.showEditAddressBol = false
          } else {
            this.showEditAddressBol = true
          }
          this.getDetailedAddress()
          this.clientDisabled = true
          this.isCompanyNameList = false

          this.$refs['ruleForm'].clearValidate([
            'customerEmail',
            'companyName',
            'linkman',
            'customerPhone',
          ])
        } else {
          this.customerEmailBlackList(item, 1)
        }
      },

      // 选择公司名称
      selectCompanyName(item) {
        // companyStatus:2 黑名单公司
        if (item.companyStatus === 2) {
          this.$message.warning(this.$t('reqmsg.M2068'))
          this.form.companyName = item.companyName
          this.isCompanyNameList = false
        } else {
          this.form.companyName = item.companyName
          this.isCompanyNameList = false
        }
      },

      // 返回上一页
      goBack() {
        this.$router.push('/order/inquiryManage/inquiryList')
      },

      // 选择客户代表
      businessChange() {
        this.businessType = 1
        this.$refs['UserChoose'].showAddEdit(
          '1',
          deepCopy(this.businessArray),
          true
        )
      },

      // 删除客户代表
      clearBusinessName() {
        this.form.businessId = ''
        this.form.businessName = ''
        this.businessArray = []
      },

      // 删除订单支持
      clearFromBusinessName() {
        this.form.fromBusinessId = ''
        this.form.fromBusinessName = ''
        this.form.fromBusinessNameEn = ''
        this.formBusinessArray = []
      },

      // 选择订单支持
      fromBusinessChange() {
        this.businessType = 2
        this.$refs['UserChoose'].showAddEdit(
          '2',
          deepCopy(this.formBusinessArray)
        )
      },

      // 选择客户代表
      chooseBusiness(user) {
        const form = this.form
        form.businessId = user ? user.userId : ''
        form.businessName = user ? user.userName : ''
        form.businessNameEn = user ? user.englishName : ''
      },

      // 获取客户代表
      chooseUser(userIdArr) {
        if (this.businessType == 1) {
          this.businessArray = userIdArr
          this.form.businessId = userIdArr[0].id
          this.form.businessName = userIdArr[0].name
          this.form.businessNameEn = userIdArr[0].englishName
        } else {
          this.formBusinessArray = userIdArr
          let [ids, names, namesEn] = [[], [], []]
          userIdArr.forEach((item) => {
            ids.push(item.id)
            names.push(item.name)
            namesEn.push(item.englishName)
          })
          this.form.fromBusinessId = String(ids)
          this.form.fromBusinessName = String(names)
          this.form.fromBusinessNameEn = String(namesEn)
        }
      },

      // 选取产品
      getProduct() {
        this.$refs['chooseProduct'].isEdit = false
        this.$refs['chooseProduct'].showAddEdit('showSaveBtn')
      },

      // 编辑产品
      edit(i) {
        const rows = this.form.products[i]
        this.chooseIndex = i
        // 新增的产品
        if (rows.newFlag || !rows.productCode) {
          this.addProduct(rows)
        } else {
          this.$refs['chooseProduct'].isEdit = true
          this.$refs['chooseProduct'].getDetail(rows)
        }
      },

      // 选择产品新增产品回调
      chooseProduct(data, isEdit) {
        if (isEdit) {
          this.$set(
            this.form.products,
            this.chooseIndex,
            JSON.parse(JSON.stringify(data))
          )
          this.$message.success(this.$t('reqmsg.$9'))
        } else {
          this.form.products.push({ ...data })
        }
      },

      // 新增产品回调
      addProduct(rows) {
        if (rows) {
          this.$refs.AddProduct.isEdit = true
          this.$refs.AddProduct.form = JSON.parse(JSON.stringify(rows))
        }
        this.$refs.AddProduct.modelVisible = true
      },

      // 删除产品
      del(i) {
        this.$confirm(this.$t('reqmsg.$12'), this.$t('reqmsg.M2005'), {
          type: 'warning',
        }).then(() => {
          this.form.products.splice(i, 1)
          this.$refs['ruleForm'].clearValidate([
            'products.' + [i] + '.productEn',
            'products.' + [i] + '.amount',
          ])
        })
      },

      // 获取上传文件地址
      uploadList(fileList) {
        this.form.accessoryUrl = JSON.stringify(fileList)
      },

      // 保存
      save() {
        this.$refs['ruleForm'].validate(async (valid) => {
          if (valid) {
            this.saveLoading = true
            const params = this.dataProcessing()
            const res = await saveUpdatePO(params)

            if (res && res.code === '000000') {
              this.$message.success(this.$t('reqmsg.$9'))
              this.$router.push('/order/inquiryManage/inquiryList')
            }
            this.saveLoading = false
          }
        })
      },

      // 生成询盘单
      createInquiry(type) {
        this.$refs['ruleForm'].validate(async (valid) => {
          if (valid) {
            if (!this.form.products.length) {
              this.$message.warning(this.$t('other.addedProduct'))
              return false
            }
            if (!this.form.companyCountry) {
              this.$message.error(
                this.lang === 'en'
                  ? 'Please complete the detailed address of the company!'
                  : '请完善公司详细地址!'
              )
              return false
            }
            if (
              this.form.companyCountry === 'US' &&
              !this.form.companyProvince
            ) {
              this.$message.error(
                this.lang === 'en'
                  ? 'Please complete the detailed address of the company!'
                  : '请完善公司详细地址!'
              )
              return false
            }
            if (this.enquiryId) {
              this.submitLoading = true
              const params = this.dataProcessing(2)
              const res = await orderEnquiryUpdate(params)

              if (res && res.code == '000000') {
                this.$message.success(this.$t('reqmsg.$9'))
                this.$router.push('/order/inquiryManage/inquiryList')
                this.sendEmail()
              }
              this.submitLoading = false
            } else {
              type == 1
                ? (this.addLoading = true)
                : (this.submitLoading = false)
              const params = this.dataProcessing(2)
              const res = await orderEnquiryAdd(params)

              if (res && res.code == '000000') {
                this.$message.success(this.$t('reqmsg.$9'))
                if (this.$route.query.mailId) {
                  // 关联邮件
                  this.$store.dispatch('mail/setMailTag', {
                    mailId: this.$route.query.mailId,
                    businessJson: res.data.enquiryCode,
                  })
                }
                //  type 1：确定并继续新增，2确认
                if (type == 1) {
                  this.form = this.$options.data().form
                  this.$refs.ErpUpload.uploadListEmpty()
                  this.$message.success(this.$t('reqmsg.G001'))
                  this.$nextTick(() => {
                    this.$refs.ruleForm.resetFields()
                  })
                } else {
                  this.$router.push('/order/inquiryManage/inquiryList')
                }

                this.sendEmail()
              }
              this.addLoading = false
              this.submitLoading = false
            }
          }
        })
      },

      /**
       * 保存确认数据处理
       * @param type  2:提交
       */
      dataProcessing(type) {
        const params = {
          ...this.form,
          buttonType: type || '',
          enquiryId: this.isClone ? '' : this.enquiryId,
        }

        const { enquiryId, clone } = this.$route.query
        // 编辑页
        if (enquiryId && !clone) {
          const {
            productsCopy,
            form: { products },
          } = this
          const propertys = [
            'productUrl',
            'productCode',
            'productEn',
            'productOperationEn',
            'productOperation',
            'specification',
            'selectQuantity',
            'printingWayEn',
            'printingWay',
            'waySelect',
            'digitSelect',
            'printingPosition',
            'demand',
            'shippingCountry',
            'deliveryToTheDoor',
            'zipCode',
            'shippingAddress',
          ]
          const bool = this.arraysEqual(productsCopy, products, propertys)
          params.logList = bool ? [] : ['products']
        }

        return params
      },

      // 比较2个数组是否相同，传入2个数组对象，和需要比较的字段，返回布尔值
      arraysEqual(arr1, arr2, propertys) {
        if (arr1.length !== arr2.length) return false
        for (let i = 0; i < arr1.length; i++) {
          for (const item of propertys) {
            if (arr1[i][item] !== arr2[i][item]) {
              return false // 遍历比较传入的字段，值不相等，返回false
            }
          }
        }
        return true
      },

      // 发送邮件
      sendEmail() {
        if (this.sendMailToCustomer) {
          let routeData = this.$router.resolve({
            path: '/write',
            query: {
              from: 'business',
              file: '',
              email: this.form.customerEmail,
              enquiryId: this.enquiryId ?? '',
            },
          })
          this.utils.openWindow(routeData.href)
        }
      },

      // 选择备注
      chooseRemake(val) {
        const item = this.quickRemaks.find((item) => item.code === val)

        this.form.orderSource = val
        // 选择了其他，可编辑
        if (val === 99) {
          this.form.demand = ''
        } else {
          this.form.demand =
            this.lang === 'en' ? item.messageEn : item.messageCn
        }
      },

      // 大图预览
      handlePreview(item) {
        if (Array.isArray(item)) {
          this.imgUrl = item
        } else {
          this.imgUrl = [item]
        }
        this.showViewer = true
      },

      // 取消预览
      closeViewer() {
        this.showViewer = false
      },
      // 新增客户地址
      addCompanyAddress() {
        this.$refs.CompanyAddress.showCustomerAddress(
          this.form,
          this.countryOptions
        )
      },
    },
  }
</script>

<style scoped lang="scss">
  .custom-container {
    overflow-x: hidden;
    padding-bottom: 0 !important;

    .disable-color {
      color: #c0c4cc;
    }

    .inquiry-content {
      padding: 0 20px 0;

      h4 {
        font-weight: 600;
        font-size: 14px;
        margin: 20px 0 10px 0;
      }

      .el-input,
      .el-select {
        width: 100%;
      }

      .company-name {
        position: relative;

        .list {
          position: absolute;
          top: 60px;
          left: 0;
          width: 100%;
          z-index: 999;
          box-shadow: 0 2px 12px 0 #ccc;
          border: 1px solid #e4e7ed;
          background: white;
          padding: 5px 0;

          p {
            font-size: 12px;
            color: #999;
            text-align: left;
            display: flex;
            justify-content: space-between;

            i {
              font-size: 18px;
              color: #333;
              cursor: pointer;
            }
          }

          ul {
            min-height: 200px;
            max-height: 200px;
            overflow-y: auto;

            li {
              padding: 0 10px 5px;
              height: 34px;
              line-height: 34px;
              display: flex;
              justify-content: space-between;
              cursor: pointer;

              span {
                width: 45%;
              }
            }

            li:hover {
              background: #f5f7fa;
            }
          }
        }
      }
    }

    .oper-btn {
      position: sticky;
      bottom: 0;
      text-align: center;
      background: white;
      padding: 10px 0;
      z-index: 2;
    }

    .wrapper-mail {
      margin-top: 10px;
    }

    .product-image {
      position: relative;
      padding: 10px 0;

      img {
        width: 66px;
        height: 66px;
        display: flex;
        margin: 0 auto;
      }

      span {
        font-size: 12px;
        position: absolute;
        top: 5px;
        right: 15px;
        background: #e8f4ff;
        border: 1px solid #d1e9ff;
        color: #409eff;
        line-height: 16px;
      }
    }

    .product-info {
      text-align: left;
      line-height: 20px;
      padding: 10px 0;
    }

    .quick-remake {
      width: 100%;
    }
  }
</style>
