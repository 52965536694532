<template>
  <!-- 新增产品 -->
  <el-dialog
    width="800px"
    :title="$t('addInquiry.AddProduct')"
    :visible.sync="modelVisible"
    :before-close="close"
    :close-on-click-modal="false"
    append-to-body
  >
    <el-form
      :model="form"
      :rules="rules"
      ref="ruleForm"
      :label-width="lang === 'en' ? '160px' : '120px'"
    >
      <!-- 产品名称 -->
      <el-form-item
        :label="$t('inquiryList.ProductName')"
        prop="productEn"
        :rules="[
          {
            required: true,
            message: $t('placeholder.plsInput'),
            trigger: 'blur',
          },
        ]"
      >
        <el-input
          v-model="form.productEn"
          maxlength="50"
          :placeholder="$t('placeholder.plsInput')"
          clearable
        ></el-input>
      </el-form-item>
      <!-- 询盘数量 -->
      <el-form-item
        :label="$t('inquiryList.InquiryQuantity')"
        prop="selectQuantity"
      >
        <div class="flex">
          <el-input
            v-model="addNums"
            type="number"
            :min="1"
            maxlength="10"
            @keyup.enter.native="addNumbers(addNums)"
            :placeholder="$t('placeholder.plsInput')"
            clearable
          ></el-input>
          <!-- 添加 -->
          <el-button
            type="primary"
            class="ml10"
            @click="addNumbers(addNums)"
            :disabled="!addNums"
          >
            {{ $t('addInquiry.Add') }}
          </el-button>
        </div>
      </el-form-item>
      <el-form-item class="num-style" v-show="numbers.length">
        <!-- 已选数量： -->
        {{ $t('addInquiry.SelectedNumber') }}
        <el-tag
          :key="tag"
          v-for="tag in numbers"
          closable
          @close="handleClose(tag)"
        >
          {{ tag }}
        </el-tag>
      </el-form-item>

      <!-- 运输信息 -->
      <p class="dark">{{ $t('addInquiry.TransportInformation') }}</p>
      <el-row :gutter="lang === 'en' ? 150 : 0">
        <el-col :span="12">
          <!-- 收货国家 -->
          <el-form-item
            :label="$t('addInquiry.ReceivingCountries')"
            prop="country"
          >
            <el-select
              v-model="form.shippingCountry"
              :placeholder="$t('placeholder.plsSel')"
              clearable
            >
              <el-option
                v-for="item in countryOptions"
                :key="item.code"
                :label="lang === 'en' ? item.nameEn : item.areaName"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <!-- 派送到门 -->
          <el-form-item
            :label="$t('addInquiry.DeliveryDoor')"
            prop="deliveryToTheDoor"
          >
            <el-switch
              v-model="form.deliveryToTheDoor"
              :active-value="1"
              :inactive-value="0"
            ></el-switch>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- 邮编 -->
      <el-form-item
        :label="$t('addInquiry.Postcode')"
        prop="zipCode"
        v-show="form.deliveryToTheDoor"
      >
        <el-input
          v-model="form.zipCode"
          maxlength="50"
          :placeholder="$t('placeholder.plsInput')"
          clearable
        ></el-input>
      </el-form-item>
      <!-- 收货地址 -->
      <el-form-item
        :label="$t('addInquiry.ShippingAddress')"
        prop="shippingAddress"
        v-show="form.deliveryToTheDoor"
      >
        <el-input
          type="textarea"
          :rows="3"
          maxlength="200"
          show-word-limit
          :placeholder="$t('placeholder.plsInput')"
          clearable
          v-model="form.shippingAddress"
        ></el-input>
      </el-form-item>
      <!-- 需求描述 -->
      <el-form-item
        :label="$t('addInquiry.RequirementDescription')"
        prop="demand"
      >
        <el-input
          type="textarea"
          :rows="3"
          maxlength="500"
          show-word-limit
          :placeholder="$t('placeholder.plsInput')"
          clearable
          v-model="form.demand"
        ></el-input>
      </el-form-item>
    </el-form>

    <div class="text-center mt20">
      <!-- 确认 -->
      <el-button type="primary" @click="save()">
        {{ $t('inquiryList.Confirm') }}
      </el-button>
      <!-- 取消 -->
      <el-button @click="close()">{{ $t('inquiryList.Cancel') }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: ['countryOptions'],
  data() {
    return {
      form: {
        productEn: '', // 产品名称
        selectQuantity: '', // 询盘数量
        shippingCountry: 'US', // 收货国家
        zipCode: '', // 邮编
        shippingAddress: '', // 收货地址
        demand: '', // 需求描述
        deliveryToTheDoor: 1, // 派送到门
      },
      addNums: '',
      numbers: [],
      rules: {},
      isEdit: false,
      modelVisible: false,
    }
  },
  computed: {
    ...mapGetters({
      lang: 'settings/language',
    }),
  },
  watch: {
    modelVisible(val) {
      if (val && this.form.selectQuantity) {
        this.numbers = this.form.selectQuantity.split(',')
      }
    },
  },
  methods: {
    // 确认
    save() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.form.selectQuantity = this.numbers.join(',')
          this.$emit('chooseProduct', this.form, this.isEdit)
          this.close()
        }
      })
    },

    // 添加数量
    addNumbers(num) {
      if (num > 0) {
        if (this.numbers.length > 4) {
          this.$message.warning(this.$t('inquiryDetails.Tips22'))
          return false
        }
        if (this.numbers.includes(num)) {
          this.addNums = ''
        } else {
          this.numbers.push(num)
          this.addNums = ''
        }
      }
    },

    // 删除已选数量
    handleClose(tag) {
      this.numbers.splice(this.numbers.indexOf(tag), 1)
    },

    // 取消
    close() {
      this.addNums = ''
      this.numbers = []
      this.isEdit = false
      this.$refs.ruleForm.resetFields()
      this.form = this.$options.data().form
      this.modelVisible = false
    },
  },
}
</script>

<style lang="scss" scoped>
p {
  text-indent: 15px;
  margin-bottom: 20px;
}
.num-style {
  margin-top: -10px;
}
::v-deep {
  .el-input {
    width: 300px;
  }
}
</style>
